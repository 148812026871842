$background:#ecedf7;
$color:#29334c;

/*Color variables*/

$primary1:#1cb5b4;
$primary2:#0238b1;
$primary3:#f44178;
$primary4:#6d4492;
$primary5:#26a9fd;
$primary6:#832afc;
$primary7:#2b9277;
$primary8:#6e4c98;
$primary9:#3b6cca;
$primary10:#1c46c5;

$secondary1:#a72071;
$secondary2:#dd4d4a;
$secondary3:#f98d29;
$secondary4:#dd610e;
$secondary5:#fb5367;
$secondary6:#fd5e40;
$secondary7:#f52b2b;
$secondary8:#d63f93;
$secondary9:#d66b10;
$secondary10:#ff3d70;

$info:#0ab2e6;
$success:#17d092;
$warning:#ffa012;
$danger:#fc3d50;
$blue:#467fcf;
$yellow:#ffa22b;
$teal:#136dba;
$purple:#604dd8;
$gray-dark:#343a40;
$indigo:#6574cd;
$white:#fff;
$black:#000;

/*white variables*/

$white-1:rgba(255, 255, 255, 0.1);
$white-2:rgba(255, 255, 255, 0.2);
$white-3:rgba(255, 255, 255, 0.3);
$white-4 :rgba(255, 255, 255, 0.4);
$white-5 :rgba(255, 255, 255, 0.5);
$white-6 :rgba(255, 255, 255, 0.6);
$white-7 :rgba(255, 255, 255, 0.7);
$white-8 :rgba(255, 255, 255, 0.8);
$white-9 :rgba(255, 255, 255, 0.9);
$white-05:rgba(255, 255, 255, 0.05);
$white-08:rgba(255, 255, 255, 0.08);
$white-75:rgba(255, 255, 255, 0.075);

/*black variables*/

$black-1:rgba(0, 0, 0, 0.1);
$black-2:rgba(0, 0, 0, 0.2);
$black-3:rgba(0, 0, 0, 0.3);
$black-4:rgba(0, 0, 0, 0.4);
$black-5:rgba(0, 0, 0, 0.5);
$black-6:rgba(0, 0, 0, 0.6);
$black-7:rgba(0, 0, 0, 0.7);
$black-8:rgba(0, 0, 0, 0.8);
$black-9:rgba(0, 0, 0, 0.9);
$black-05:rgba(0, 0, 0, 0.05);
